import React from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import Layout from '../components/v2/templates/Layout';
import { Section } from '../components/v2/templates/Section';
import Notification from '../components/v1/molecules/notification';
import ContactUsForm from '../components/v1/Form/contactUsForm';
import useStoryblok from '../lib/storyblok';
import Bloks from '../components/atoms/Bloks';
import Grid from '../components/v2/atoms/Grid/Grid';
import BookDemoIllustration from '../images/walking-with-speechbubbles.svg';
import { StoryblokPage } from '../types';

const ContactUs: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />
      <Section curved noGrid hero curveColor="tertiary">
        <Bloks bloks={content.hero} className="col-span-4 lg:col-span-6" />
      </Section>
      <Grid>
        <ContactUsForm className="shadow-2xl col-span-4 sm:col-start-2 sm:col-end-8 sm:!max-w-full m-0 lg:col-start-7 lg:col-end-12 w-full" />
        <img
          src={BookDemoIllustration}
          className="min-w-[330px] col-span-4 w-full mt-8 sm:mt-20 sm:col-start-3 sm:col-end-7 lg:col-start-2 lg:col-end-6 lg:row-start-1 self-end"
        />
      </Grid>

      <Notification isVisible={queryString.parse(location.search).formSubmit === 'success'} />
    </Layout>
  );
};

export default ContactUs;

export const query = graphql`
  query ContactUsQuery {
    storyblokEntry(full_slug: { eq: "contact-us" }) {
      content
      name
    }
  }
`;
