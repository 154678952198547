import React, { useState } from 'react';
import { Form, TextField, TextAreaField, SelectField } from '.';
import validateEmail from '../../../utils/validateEmail';
import { Button } from '../atoms/Button';

type Props = {
  className?: string;
};

const ContactUsForm: React.FC<Props> = ({ className }) => {
  const productOptions = [
    { value: '', label: 'Select' },
    { value: 'Build to rent', label: 'Build to rent' },
    { value: 'CRM', label: 'CRM' },
    { value: 'Landlord', label: 'Landlord' },
    { value: 'Letting agent', label: 'Letting agent' },
    { value: 'Marketplace', label: 'Marketplace' },
    { value: 'Proptech', label: 'Proptech' },
    { value: 'Tenant', label: 'Tenant' },
    { value: 'Other', label: 'Other' },
  ];

  const [emptyFields, setEmptyFields] = useState(['phone_number', 'first_name', 'last_name']);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [productSelected, setProductSelected] = useState(productOptions[0].value);

  const checkLength = (value: string, fieldName: string) => {
    if (value.trim().length === 0 && emptyFields && !emptyFields.includes(fieldName)) {
      return setEmptyFields((emptyFields) => [...emptyFields, fieldName]);
    } else {
      const updatedEmptyFields = emptyFields.filter((emptyField) => emptyField !== fieldName);
      return setEmptyFields(updatedEmptyFields);
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    checkLength(e.target.value, e.target.name);

  const handleChangeEmailInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    setIsEmailValid(validateEmail(e.target.value.trim()));

  return (
    <Form
      action="/contact-us/?formSubmit=success"
      formName="contact-us-form"
      transparentBackground
      className={className}
    >
      <TextField
        label="First name"
        id="first_name"
        type="text"
        placeholder="John"
        autoComplete="given-name"
        required
        onChange={handleChangeInput}
        fullWidthTextField
      />
      <TextField
        label="Last name"
        id="last_name"
        type="text"
        placeholder="Doe"
        autoComplete="family-name"
        required
        onChange={handleChangeInput}
        fullWidthTextField
      />
      <TextField
        label="Email address"
        id="email_address"
        type="email"
        placeholder="name@domain.com"
        autoComplete="email"
        required
        onChange={handleChangeEmailInput}
        fullWidthTextField
      />
      <TextField
        label="Phone number"
        id="phone_number"
        type="tel"
        placeholder="07400 123456"
        autoComplete="tel"
        required
        onChange={handleChangeInput}
        fullWidthTextField
      />
      <SelectField
        label="Product"
        id="product"
        options={productOptions}
        selected={productSelected}
        required
        onBlurHandler={(e) => setProductSelected(e.target.value)}
        onChangeHandler={(e) => setProductSelected(e.target.value)}
        fullWidthTextField
      />
      <TextAreaField label="Message" id="message" placeholder="" fullWidthTextField />
      <Button
        type="submit"
        label="Submit"
        disabled={emptyFields.length >= 1 || !isEmailValid || productSelected === ''}
      />
    </Form>
  );
};

export default ContactUsForm;
